import { FC, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, api, Box } from '../../components';
import { useUserContext } from '../../context';
import { UserStateType } from '../../types';
import { Login as Text, Account as TextSettings, Error } from '../../language';

export const SetPasswordConfirmationCode: FC<{
  userEmail?: string;
  setForgottenPassworForm?: (data: boolean) => void;
}> = ({ setForgottenPassworForm, userEmail }) => {
  const [formError, setFormError] = useState('');
  const [email, setEmail] = useState(userEmail || '');
  const [showConfirmationForm, setShowConfirmationForm] = useState(false);
  const [, setStore] = useUserContext();
  const history = useHistory();

  const forgottenPasswordHandler = useCallback((formData, clearForm?) => {
    setEmail(formData.email);

    api('user/sendConfirmationCode' ,formData , (data) => {
      if (data.error) setFormError(Error(data.error));
      else if (data.status === 'confirmationCode') {
        setFormError('');
        setShowConfirmationForm(true);
      }
    });
  }, []);

  const checkConfirmationCode = useCallback(
    (formData, clearForm) => {
      api('user/checkConfirmationCode', { email: email, confirmationCode: formData.confirmationCode  },
        (data) => {
          setFormError('');
          if (data.error) setFormError(Error(data.error));
          else {
            clearForm();

            !userEmail && history.push('/password');
            setStore({ ...(data.userData as UserStateType), confirmationCode: formData.confirmationCode });
          }
        },
      );
    },
    [email, history, setStore, userEmail],
  );

  if (showConfirmationForm) {
    return (
      <Box title={TextSettings.RECOVER_PASSWORD} error={formError} centeredTitle={true}>
        <Form
          callBack={checkConfirmationCode}
          formData={{
            buttonLabel: Text.CONFIRM,
            secondButtonLabel: Text.CANCEL,
            secondButtonCallback: () => setShowConfirmationForm(false),
            fields: [
              {
                label: Text.CONFIRMATION_CODE,
                fieldName: 'confirmationCode',
                fieldType: 'number',
                required: true,
              },
            ],
          }}
        />
      </Box>
    );
  }

  return (
    <Box title={TextSettings.RECOVER_PASSWORD} subtitle={userEmail} error={formError} centeredTitle={true}>
      <Form
        callBack={forgottenPasswordHandler}
        formData={{
          buttonLabel: Text.SEND_CONFIRMATION_CODE,
          secondButtonLabel: setForgottenPassworForm && Text.CANCEL,
          secondButtonCallback: () => setForgottenPassworForm && setForgottenPassworForm(false),
          fields: [
            {
              label: Text.EMAIL,
              fieldName: 'email',
              fieldType: userEmail ? 'hidden' : 'email',
              required: true,
            },
          ],
        }}
      />
    </Box>
  );
};
