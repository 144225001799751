export const TITLE = 'Create Free QR Codes';
export const TEXT =
  'A Quick Response (QR) code is a pattern of black and white squares that can be read by a smart phone, allowing the phone user to get more information about something.';

export const TEXT2 =
  'The three square outlines in the bottom-left, top-left, and top-right corners define the orientation of the code. The dots within the QR code contain format and version information as well as the content itself.';

export const TITLE2 = 'Download Your QR Codes';

export const TEXT3 =
  "Once you've created your QR Codes you can download them as PNG image files and print or email them to yourself or someone else. Just choose the Export Type you need.";

export const TEXT4 = 'You have access to your QR codes archive at any time from your account.';

export const BUTTON_TEXT = 'GET STARTED »';
export const BUTTON_TEXT2 = 'DEMO';
