import { useCallback, useState, useEffect } from 'react';
import { Form, api, Box } from '../../../components';
import { Account as Text, Error } from '../../../language';
import { useUserContext } from '../../../context';
import { SetPasswordConfirmationCode } from '../../Login/SetPasswordConfirmationCode';

export const SetPassword = () => {
  const [store] = useUserContext();
  const [formError, setFormError] = useState('');
  const [successfulMessage, setSuccessfulMessage] = useState('');

  useEffect(() => {
    setSuccessfulMessage('');
  }, []);

  const changePasswordHandler = useCallback((formData, clearForm) => {
    api('user/password', { setPassword: formData.password, setPassword2: formData.password2 },
      (data) => {
        setFormError('');
        if (data.error) setFormError(Error(data.error));
        else if (data.status === 'ok') setSuccessfulMessage(Text.PASSWORD_WAS_CHANGED);
      },
    );
  }, []);

  if (!store.confirmationCode) return <SetPasswordConfirmationCode userEmail={store.email} />;

  if (successfulMessage) {
    return <Box title={successfulMessage} centeredTitle={true} />;
  }
  return (
    <Box title={Text.CHANGE_PASSWORD} centeredTitle={true}>
      <Form
        callBack={changePasswordHandler}
        formError={formError}
        formData={{
          buttonLabel: Text.CONFIRM,
          fields: [
            {
              label: Text.PASSWORD,
              fieldName: 'password',
              fieldType: 'password',
              required: true,
            },
            {
              label: Text.RETYPE_PASSWORD,
              fieldName: 'password2',
              fieldType: 'password',
              required: true,
            },
          ],
        }}
      />
    </Box>
  );
};
