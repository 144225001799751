import { FC, useState, useCallback } from 'react';
import { DraggableItems, DeleteElementContainer } from './DragAndDropList.style';
import DragOverContainer from './DragOverContainer';
import NavItemElement from './NavItemElement';

interface SectionParams {
  items: JSX.Element[] | null | undefined;
  data: [] | null;
  onDragAndDrop: (elementIndex: number, newPositionIndex: number, changedData: []) => void;
  enableDelete?: boolean;
  border?: boolean;
}

export const DragAndDropList: FC<SectionParams> = ({ items, data, onDragAndDrop, enableDelete = false, border }) => {
  const [dragElement, setDragElement] = useState(-1);
  const [dragElementOver, setDragElementOver] = useState(-1);

  const onDropOverHandler = useCallback((): void => {
    if (data) {
      let changedData: {}[] = [];

      //delete item
      if (dragElementOver === 999999) {
        changedData = data.filter((item, index) => index !== dragElementOver);
      } else {
        data.map((item, i) => {
          if (i + 1 === dragElementOver) changedData.push(data[dragElement]);

          if (i >= 0 && i < data.length && i !== dragElement) changedData.push(data[i]);

          //last position
          if (i + 1 === data.length && dragElementOver === data.length + 1) {
            changedData.push(data[dragElement]);
          }

          return null;
        });
      }

      setDragElementOver(-1);
      setDragElement(-1);

      return onDragAndDrop(dragElement, dragElementOver, changedData as []);
    }
  }, [data, dragElement, dragElementOver, onDragAndDrop]);

  if (!items || !data) {
    return null;
  }

  return (
    <>
      {!enableDelete ? null : (
        <DeleteElementContainer>
          <DragOverContainer
            index={999999}
            setDragElementOver={setDragElementOver}
            setDragElement={setDragElement}
            onDropOverHandler={onDropOverHandler}
            dragElementOver={dragElementOver}
            dragActive={dragElement > -1}
          >
            ✕
          </DragOverContainer>
        </DeleteElementContainer>
      )}
      <DraggableItems dragactive={dragElement > -1}>
        {items.map((item, index) => (
          <NavItemElement
            content={item}
            index={index}
            key={String(index)}
            setDragElementOver={setDragElementOver}
            setDragElement={setDragElement}
            onDropOverHandler={onDropOverHandler}
            dragElementOver={dragElementOver}
            dragActive={dragElement > -1}
            border={border}
          />
        ))}
      </DraggableItems>
    </>
  );
};
