import styled from 'styled-components';
import { COLORS } from '../../constants/colors';

export const ServiceTitle = styled.div`
  font-size: 1.1rem;
  color: ${COLORS.textColor};
`;
export const ServiceDetails = styled.div`
  margin-top: 15px;
  font-size: 0.8rem;
  color: ${COLORS.textColorLight};
`;
export const ServicePrice = styled.span`
  background: ${COLORS.secondColor}20;
  color: ${COLORS.secondColor};
  border-radius: 1em;
  display: flex;
  padding: 0.4em 0.8em;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;
  font-weight: 600;
  margin-left: 2em;
`;
export const ServiceInfo = styled.div`
  flex: 1;
`;
export const Service = styled.div<{ cursorPointer: boolean; active?: boolean; noBorder?: boolean }>`
  text-align: left;
  margin: 10px 0;
  min-width: 90px;
  background-color: ${COLORS.boxBackgroundColor};
  ${(props) => !props.noBorder && `border: 1px solid ${COLORS.boxBorderColor}`};
  ${(props) => !props.noBorder && `padding: 15px`};
  border-radius: 5px;
  ${(p) => p.cursorPointer && 'cursor: pointer;'}
  ${(p) =>
    p.active &&
    `
    background-color: ${COLORS.boxBackgroundColorHover};`}

& > span {
    ${(p) =>
      p.active &&
      `
      background: none;`};
  }
  display: flex;

  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 80px;
  box-sizing: border-box;
  &:hover {
    background-color: ${COLORS.boxBackgroundColorHover};
    & > span {
      background: none;
    }
  }

  @media (max-width: 767px) {
    display: block;
    & > span {
      margin: 5px 0;
    }

    & > button {
      margin: 5px 5px 0 0;
    }
  }
`;

export const ServiceHeader = styled.div`
  font-size: 0.8rem;
  font-weight: bold;
  margin-bottom: 6px;
`;
