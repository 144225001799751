export const PASSWORD = 'Password';
export const RETYPE_PASSWORD = 'Retype password';
export const NAME = 'Your name';
export const FIRST_NAME = 'First name';
export const LAST_NAME = 'Last name';
export const COMPANY_NAME = 'Company name';
export const USERNAME = 'Username';
export const PROFILE_TITLE = 'Title / position';
export const SIGNUP = 'Registration';
export const EMAIL = 'E-mail address';
export const SETTINGS = 'Settings';
export const CONFIRM = 'Confirm';
export const CHANGE_PASSWORD = 'Change your password';
export const RECOVER_PASSWORD = 'Recover your password';
export const PASSWORD_WAS_CHANGED = 'The password has been changed.';
export const PROFILE_PHOTO = 'Avatar';
