import { FC } from 'react';
import { BoxContainer, BoxTitle, BoxSubtitle, BoxSection, BoxText, BoxError, BoxTitleContainer } from '../styled';

export const Box: FC<{
  title?: string;
  subtitle?: string;
  error?: string;
  align?: string;
  centeredTitle?: boolean;
  centeredContent?: boolean;
}> = ({ children, title, subtitle, error, align, centeredTitle, centeredContent }) => {
  return (
    <BoxContainer>
      <BoxSection align={align}>
        <BoxTitleContainer centered={centeredTitle}>
          {title && <BoxTitle>{title}</BoxTitle>}
          {subtitle && <BoxSubtitle>{subtitle}</BoxSubtitle>}
          {error && <BoxError>{error}</BoxError>}
        </BoxTitleContainer>
        <BoxText centered={centeredContent}>{children}</BoxText>
      </BoxSection>
    </BoxContainer>
  );
};
