import { useState } from 'react';
import QRCode from 'qrcode.react';
import { Option, Form } from '../';
import { saveAs } from 'file-saver';

export const QRCodeDemo = () => {
  const [data, setData] = useState('');

  const exportPNG = () => {
    const canvas: any = document.querySelector('.HpQrcode canvas');

    canvas.toBlob(function (blob: any) {
      saveAs(blob, 'qr.png');
    });
  };

  return (
    <div className="HpQrcode">
      <Option
        avatar={data ? <QRCode value={data} size={128} level={'H'} renderAs={'canvas'} /> : undefined}
        title=""
        description={
          <Form
            formData={{
              fields: [
                {
                  label: 'QR Code Content',
                  fieldName: 'description',
                  required: true,
                  fieldType: 'textarea',
                  defaultValue: '',
                  onChange: (d) => setData(d),
                },
              ],
            }}
          />
        }
        noBorder={true}
        buttons={
          data
            ? [
                {
                  title: 'Export PNG',
                  onClick: () => exportPNG(),
                  active: true,
                },
              ]
            : []
        }
      />
    </div>
  );
};
