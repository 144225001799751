import styled, { css } from 'styled-components';
import { COLORS } from '../../constants/colors';
import { Link } from 'react-router-dom';

const buttonStyle = css<{ active?: boolean; margin?: string; fullWidth?: boolean }>`
  color: ${(props) => (props.active ? COLORS.primaryColorText : COLORS.buttonText)};
  display: ${(props) => (props.fullWidth ? 'block' : 'inline-block')};
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
  font-size: 1em;
  padding: 0.5em 1em;
  border: 0;
  border-radius: 5px;
  text-decoration: none;
  margin: ${(props) => props.margin || '0'};
  cursor: pointer;
  background-color: ${(props) => (props.active ? COLORS.primaryColor : COLORS.buttonColor)};
  background-repeat: no-repeat;
  background-position: right -20px top 50%;
  transition: 50ms;
  opacity: 0.8;
  &:hover {
    //padding: 0.5em 1.5em 0.5em 0.5em;
    //background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='16' fill='%23ffffff70'><polygon points='0,0 0,16,8,8'/></svg>");
    //background-position: right -3px top 50%;
    opacity: 1;
  }
`;

export const Button = styled(Link)`
  ${buttonStyle}
`;

export const FormButton = styled.button`
  ${buttonStyle}
`;

export const A = styled.a`
  ${buttonStyle}
`;

export const SmallButton = styled.button`
  ${buttonStyle}
  padding: 5px;
  font-size: 0.6rem;
`;

export const LandingButton = styled(Link)`
  ${buttonStyle}
  padding: 15px 35px;
  font-size: 1.1rem;
  font-weight: bolder;
`;

export const BigButton = styled.button`
  ${buttonStyle}
  padding: 15px 35px;
  font-size: 1.1rem;
  font-weight: bolder;
`;
