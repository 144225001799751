import styled from 'styled-components';
import { COLORS } from '../../constants/colors';

export const Container = styled.div`
  padding: 0 10px 0 20px;
  @media (min-width: 767px) {
    display: flex;
    padding: 40px;
    flex-direction: row;
  }
  & > img {
    flex: 1;
    width: 500px;
    max-width: 50%;
    object-fit: contain;
    margin: 0 50px;
    @media (max-width: 1140px) {
      max-width: 30%;
    }

    @media (max-width: 768px) {
      display: none;
    }
  }
`;

export const Element = styled.div`
  flex: 1;
  padding-right: 3rem;
  & > h1 {
    color: ${COLORS.titleColor};
  }
  & > p {
    line-height: 2rem;
    font-size: 1.4rem;
    color: ${COLORS.textColor};
  }
`;
