import { useState, useCallback, useEffect, useMemo } from 'react';
import QRCode from 'qrcode.react';
import { Box, Modal, Option, Form, api, DragAndDropList, ExportQRCode } from '../../../components';
import { QRCodes as Text } from '../../../language';
import { FormButton } from '../../../components/styled';
import { PostType, FormFieldType, ProfilePostType } from '../../../types';

export const Posts = () => {
  const [posts, setPosts] = useState<ProfilePostType[]>([]);
  const [modalOpened, setModalOpened] = useState(false);
  const [editPostId, setEditPostId] = useState('');
  const [modalContent, setModalContent] = useState<JSX.Element>();

  const initialFormFields: FormFieldType[] = useMemo(
    () => [
      {
        label: Text.DESCRIPTION,
        fieldName: 'description',
        required: true,
        fieldType: 'textarea',
        defaultValue: '',
      },
      {
        label: Text.BGCOLOR,
        fieldName: 'bgColor',
        required: false,
        defaultValue: '#ffffff',
        size: 'half',
      },

      {
        label: Text.FGCOLOR,
        fieldName: 'fgColor',
        required: false,
        defaultValue: '#000000',
        size: 'half',
      },
    ],
    [],
  );

  const updatePosts = useCallback(() => {
    api('posts/userPosts', {},
      (returnData) => {
        setPosts(returnData.data as ProfilePostType[]);
      },
    );
  }, []);

  useEffect(() => {
    updatePosts();
  }, [updatePosts]);

  const savePostHandler = useCallback(
    (formData, clearForm) => {
      const saveData: PostType = { ...formData };

      const data = { updateId: formData.editId, deletePost: formData.deletePost, data: saveData };

      api('posts/savePost', data,
              (returnData) => {
          clearForm();
          setEditPostId('');
          setModalOpened(false);
          updatePosts();
        },
      );
    },
    [updatePosts],
  );

  const editPostHandler = useCallback(
    (postId: string) => {
      if (postId) {
        setEditPostId(postId);

        api('posts/post', { postId: postId },
          (returnData) => {
            const post: ProfilePostType = returnData.data as ProfilePostType;

            setPosts([...posts.map((item) => (item.uuid === post.uuid ? post : item))]);

            if (post.uuid) {
              const editFormData = [
                ...initialFormFields.map((item) => {
                  const defValue = item.fieldName && post.data && post.data[item.fieldName];
                  const newItem = { ...item, defaultValue: defValue };
                  return { ...newItem };
                }),
                {
                  label: Text.DELETE,
                  fieldType: 'checkbox',
                  fieldName: 'deletePost',
                  positionRight: true,
                },
                {
                  label: '',
                  fieldType: 'hidden',
                  fieldName: 'editId',
                  defaultValue: postId,
                },
              ];

              setModalContent(
                <Option
                  title=""
                  noBorder={true}
                  description={
                    <Form
                      callBack={savePostHandler}
                      formData={{
                        buttonLabel: Text.SAVE,
                        fields: [...editFormData],
                        formKey: editPostId,
                      }}
                    />
                  }
                />,
              );

              setModalOpened(true);
            }
          },
        );
      }
    },
    [editPostId, initialFormFields, posts, savePostHandler],
  );

  const sortOrder = useCallback(
    (reorderedArray) => {
      const postOrder: [] = reorderedArray.map((item: ProfilePostType) => item.uuid);

      api('posts/sort',{ orderById: postOrder },
        (returnData) => {
          updatePosts();
        },
      );
    },
    [updatePosts],
  );

  const newPostHandler = useCallback(() => {
    setModalContent(
      <Form
        callBack={savePostHandler}
        formData={{
          buttonLabel: Text.SAVE,
          fields: [...initialFormFields],
        }}
      />,
    );

    setModalOpened(true);
  }, [initialFormFields, savePostHandler]);

  const exportHandler = useCallback((data: PostType) => {
    if (data.description) {
      console.log(data.qrType === '1');

      setModalContent(<ExportQRCode data={data} />);
      setModalOpened(true);
    }
  }, []);

  return (
    <Box title={Text.TEXT_MY_POSTS}>
      <p>
        <FormButton onClick={newPostHandler} active={true}>
          {Text.ADD}
        </FormButton>
      </p>
      <DragAndDropList
        items={posts?.map((item, index) => (
          <Option
            key={String(index)}
            title={item.data?.description || '/'}
            buttons={[
              {
                title: Text.EXPORT_QR_CODE,
                onClick: () => exportHandler(item.data),
                active: true,
              },
              {
                title: Text.EDIT,
                onClick: () => editPostHandler(item.uuid),
              },
            ]}
            avatar={
              item.data.description ? (
                <div style={{ paddingRight: 10 }}>
                  <QRCode
                    value={item.data.description}
                    size={40}
                    level={'H'}
                    renderAs={'canvas'}
                    bgColor={item.data.bgColor}
                    fgColor={item.data.fgColor}
                  />
                </div>
              ) : undefined
            }
          />
        ))}
        onDragAndDrop={(oldPos, newPos, reorderedArray) => {
          sortOrder(reorderedArray);
        }}
        data={posts as []}
      />
      <Modal isOpen={modalOpened} setIsOpen={setModalOpened}>
        {modalContent}
      </Modal>
    </Box>
  );
};
