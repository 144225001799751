import styled from 'styled-components';
import { COLORS } from '../../constants/colors';

export const Pill = styled.span<{ active?: boolean }>`
  display: inline-block;
  padding: 5px 10px;
  margin-right: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: ${(props) => (props.active ? COLORS.secondColor : COLORS.backgroundColor)};
  color: ${(props) => (props.active ? COLORS.secondTextColor : COLORS.textColor)};
  border-radius: 10px;
  text-transform: uppercase;
  font-size: 0.7rem;
`;
