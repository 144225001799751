import { useState } from 'react';

import { Page } from '../../components/Page';
import { Homepage as TEXT } from '../../language';
import { Container, Element } from './Homepage.style';
import { LandingButton, BigButton } from '../../components/styled';
import { QRCodeDemo, Modal } from '../../components';

export const Homepage = () => {
  const [modalOpened, setModalOpened] = useState(false);

  return (
    <Page noBorder={true}>
      <Container>
        <Element>
          <h1>{TEXT.TITLE}</h1>
          <p>{TEXT.TEXT}</p>
          <p>{TEXT.TEXT2}</p>
          <BigButton onClick={() => setModalOpened(true)} style={{ marginRight: 20, marginBottom: 20 }} active={false}>
            {TEXT.BUTTON_TEXT2}
          </BigButton>
          <LandingButton active={true} to={'/signup'}>
            {TEXT.BUTTON_TEXT}
          </LandingButton>
        </Element>
        <img src="/landing.png" alt={TEXT.TITLE} />
      </Container>
      <Container>
        <img src="/landing2.png" alt={TEXT.TITLE} />

        <Element>
          <h1>{TEXT.TITLE2}</h1>
          <p>{TEXT.TEXT3}</p>
          <p>{TEXT.TEXT4}</p>
          <LandingButton active={true} to={'/signup'}>
            {TEXT.BUTTON_TEXT}
          </LandingButton>
        </Element>
      </Container>
      <Modal isOpen={modalOpened} setIsOpen={setModalOpened}>
        <QRCodeDemo />
      </Modal>
    </Page>
  );
};
