import styled, { keyframes } from 'styled-components';
import { COLORS } from '../../constants/colors';
import { SITE_WIDTH } from '../../constants/config';
import { Link } from 'react-router-dom';

const rotate = keyframes`
    from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }   
`;

export const NavIcons = styled.div<{ hidden: boolean }>`
  display: flex;
  margin-right: 20px;
  @media (max-width: 910px) {
    position: absolute;
    right: 0px;
    top: 10px;
  }
`;

export const LoggedUser = styled.div`
  text-align: right;
  margin: 0px 35px 10px 0;
  font-size: 0.8rem;
  font-weight: bold;
`;

export const Logo = styled.div`
  padding: 0px 0 0px 20px;

  @media (min-width: 767px) {
    padding: 15px 0 15px 40px;
  }
  //height: 60px;
  max-width: 30%;

  .logo {
  }

  & svg g#line1 rect {
    transition: font-size 0.25s, margin 0.25s, padding 0.25s, opacity 0.5s 0.25s;
  }
`;

export const NavigationContainerBody = styled.div<{ mobile: boolean }>`
  & {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    top: 0;
    overflow: ${(props) => (props.mobile ? 'visible' : 'hidden')};
  }
  @media (min-width: ${SITE_WIDTH}) {
    max-width: ${SITE_WIDTH};
    margin: 0 auto;
  }
`;

export const NavigationContainer = styled.div<{ noBorder?: boolean }>`
  position: fixed;
  z-index: 100;
  width: 100%;
  transition: background-color 0.5s ease;

  ${(props) => !props.noBorder && `border-bottom: 1px dotted ${COLORS.primaryColor}40`};
  ${(props) =>
    !props.noBorder ? `background-color: ${COLORS.navigationColor}` : `background: ${COLORS.backgroundColor}`};

  & a {
    color: ${COLORS.textColor};
  }
`;

export const NavItems = styled.ul`
  list-style-type: none;
  padding: 0;
  flex: 1;
  margin-top: 6px;
`;
export const NavItem = styled.li`
  display: inline;
  margin-right: 30px;

  @media (min-width: 769px) {
    margin-left: 10px;
  }
`;

export const NavLink = styled(Link)<{ selected: boolean }>`
  text-decoration: none;
  color: ${COLORS.navigationLinkColor};
  padding: 5px;
  text-transform: uppercase;
  border-bottom: 1px ${(props) => (props.selected ? COLORS.secondColor : 'none')} solid;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
  &:hover {
    border-bottom: 1px ${COLORS.primaryColor} solid;
  }
`;

export const NavBar = styled.div<{ mobile: boolean }>`
  flex: 1;

  & > ul {
    text-align: right;
  }
  @media (max-width: 767px) {
    & {
      display: block;
      position: absolute;
      background-color: ${(props) => (props.mobile ? COLORS.backgroundColor : 'transparent')};
      top: 0px;
      padding-top: 60px;
      height: 100vh;
      width: 100%;
      border-bottom: 1px solid ${COLORS.primaryColor};
      transition: 0.5s;
      transform: translateX(${(props) => (props.mobile ? '0' : '100%')});
      & a {
        ${(props) => (props.mobile ? `color: ${COLORS.textColor}` : '')};
      }
    }

    & > ul li {
      display: block;
      padding: 10px;
      font-size: 1.5rem;
      margin-right: 0;
    }
  }
`;

export const Hamburger = styled.a<{ active: boolean; scrolly: number }>`
  & {
    display: inline-block;
    width: 28px;
    border-top: 2px solid
      ${(props) => (props.active ? 'transparent' : props.scrolly ? COLORS.textColor : COLORS.secondTextColor)};
    margin: 20px;
    z-index: 1;
    cursor: pointer;
    transition: 0.3s;
  }
  & > div:first-of-type {
    transform: rotateZ(${(props) => (props.active ? '45deg' : '0')});
    transition: 0.3s;
    transform-origin: center;
  }
  & > div:last-of-type {
    transform: rotateZ(${(props) => (props.active ? '135deg' : '0')});
    margin-top: ${(props) => (props.active ? '-2px' : '6px')};
    transition: 0.3s;
    transform-origin: center;
  }
  & > div {
    & {
      border-bottom: ${(props) => (props.active ? '2px' : '2px')} solid;
      border-color: ${(props) => (props.scrolly ? COLORS.textColor : COLORS.secondTextColor)};
      margin-top: 6px;
      width: 100%;
      float: right;
    }
  }
  &:link {
    animation: ${rotate} 0.2s linear;
  }
  @media (min-width: 767px) {
    display: none;
  }
`;
