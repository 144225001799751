import React, { FC, useCallback, useState } from 'react';
import { IMAGE_PATH } from '../../../constants/config';
import { SmallButton, BoxImage, Center, ButtonContainer } from '../../../components/styled';
import { FileInputContainer } from './ProfilePhoto.style';
import { api, Alert } from '../../../components';
import { DEFAULT_PROFILE_PHOTO } from '../../../constants/config';
import { useUserContext } from '../../../context';

export const ProfilePhoto: FC<{}> = () => {
  const [store, setStore] = useUserContext();
  const [currentImage, setCurrentImage] = useState<string>();

  const deleteImageHandler = () => {
    api('user/setPhoto', 
     { filename: currentImage },
      (data) => {
        if (data.status === 'done') {
          setCurrentImage('');
          setStore({ ...store, profile: { ...store.profile, photo: '' } });
        }
      },
    );
  };

  const uploadImageHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let filename = '';
      var reader = new FileReader();
      reader.onload = () => {
        const saveData = {
          filename: filename,
          data: reader.result,
        };

        setCurrentImage(String(reader.result));

        api('user/setPhoto',  saveData , (data) => {
          if (data.error) Alert(data.error);

          if (data.status === 'done' && data.val) {
            setStore({ ...store, profile: { ...store.profile, photo: data.val } });
          }
        });
      };

      if (event.target && event.target.files && event.target.files.length) {
        if (event.target.files[0].type.substring(0, 6) === 'image/') {
          filename = event.target.files[0].name;
          reader.readAsDataURL(event.target.files[0]);
        }
      }
    },
    [setStore, store],
  );

  const inputFileRef = React.useRef<HTMLInputElement>(null);

  return (
    <Center>
      <BoxImage
        src={
          currentImage ? currentImage : store.profile?.photo ? store.profile?.photo : DEFAULT_PROFILE_PHOTO
        }
      />
      <ButtonContainer sinlgeButton={true}>
        <SmallButton
          active={true}
          onClick={() => {
            inputFileRef.current && inputFileRef.current.click();
          }}
        >
          Upload image
        </SmallButton>
        {store.profile?.photo && (
          <SmallButton active={false} onClick={() => deleteImageHandler()}>
            Delete image
          </SmallButton>
        )}
      </ButtonContainer>
      <FileInputContainer>
        <input type="file" ref={inputFileRef} onChange={uploadImageHandler} />
      </FileInputContainer>
    </Center>
  );

  //return <Input type="file" required={false} onChange={(e) => uploadImageHandler(e)} />;
};
