import { useCallback, useState, FC } from 'react';
import { Form, api, Box } from '../../components';
import { ConfirmationCode } from '../';
import { Account as Text, Error } from '../../language';

export const Signup: FC<{ modalWindow?: boolean }> = ({ modalWindow }) => {
  const [formError, setFormError] = useState('');
  const [userStatus, setUserStatus] = useState('');

  const signup = useCallback((formData, clearForm?) => {
    api('user/register', formData, (data) => {
      setUserStatus(data.status);
      if (data.error) setFormError(Error(data.error));
      else {
        setFormError('');
      }
      //clearForm();
    });
  }, []);

  if (userStatus === 'confirmationCode') return <ConfirmationCode />;

  return (
    <Box title={Text.SIGNUP} centeredTitle={true}>
      <Form
        formError={formError}
        callBack={signup}
        formData={{
          buttonLabel: Text.SIGNUP,
          fields: [
            {
              label: Text.FIRST_NAME,
              fieldName: 'firstName',
              fieldType: 'text',
              required: true,
              size: 'half',
            },
            {
              label: Text.LAST_NAME,
              fieldName: 'lastName',
              fieldType: 'text',
              required: true,
              size: 'half',
            },
            {
              label: Text.EMAIL,
              fieldName: 'email',
              fieldType: 'email',
              required: true,
            },
            // {
            //   label: Text.PASSWORD,
            //   fieldName: 'password',
            //   fieldType: 'password',
            //   required: true,
            //   size: 'half',
            // },
            // {
            //   label: Text.RETYPE_PASSWORD,
            //   fieldName: 'password2',
            //   fieldType: 'password',
            //   required: true,
            //   size: 'half',
            // },

            {
              label: Text.COMPANY_NAME,
              fieldName: 'companyName',
              fieldType: 'text',
              required: false,
            },
            {
              label: Text.PROFILE_TITLE,
              fieldName: 'title',
              fieldType: 'text',
              required: false,
            },
          ],
        }}
      />
    </Box>
  );
};
