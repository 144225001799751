import { useState, FC, useEffect, useCallback } from 'react';
import { Block } from './Form.style';
import { LineBreak } from '../styled';
import {
  FormFieldBox,
  FieldLabel,
  ButtonContainer,
  FieldErrorMessage,
  FormErrorMessage,
  FormResponseText,
  FormButton,
  FormFieldContainer,
} from '../styled';
import { FormField } from './FormField';
import { submitForm } from './SubmitForm';
import { SectionForm, FormFieldType, submitFormResponse, FormValueParams } from '../../types';
import * as Text from '../../language/form.lang';

interface SectionParams {
  formData: SectionForm;
  callBack?: (data: {}, resetForm: () => void) => void;
  formError?: string;
  formId?: string;
}

const defaultFormKey = '__form1';

export const Form: FC<SectionParams> = ({ formData, callBack, formError, formId }) => {
  const [sending, setSending] = useState(false);
  const [fieldsWithError, setFieldsWithError] = useState<string[]>([]);
  const [formFields, setFormFields] = useState<FormFieldType[]>([]);
  const [formValues, setFormValues] = useState<{ [key: string]: FormValueParams }>({});
  const [formKey, setFormKey] = useState(defaultFormKey);
  const [formResponse, setFormResponse] = useState<submitFormResponse>({
    responseText: '',
    errorMessage: '',
  });

  useEffect(() => {
    setFormResponse({ responseText: '', errorMessage: formError as string });
  }, [formError]);

  const clearForm = useCallback(() => {
    let clearEnteredFormValues = {};
    formFields.forEach((item) => {
      if (item.fieldName) clearEnteredFormValues = { ...clearEnteredFormValues, [item.fieldName]: '' };
    });

    setFormValues({});
  }, [formFields]);

  const fieldValueHandler = useCallback(
    (fieldName: string, value: string) => {
      setFormValues((currentState) => {
        if (!fieldName)
          return {
            ...currentState,
          };

        return {
          ...currentState,
          [formKey]: { ...currentState[formKey], [fieldName]: value ? value : '' },
        };
      });
    },
    [formKey],
  );

  useEffect(() => {
    setSending(false);

    if (formResponse.responseText && !formResponse.errorMessage) {
      if (callBack) callBack(formResponse, clearForm);
      // else clearForm();
    }
  }, [clearForm, formResponse, callBack]);

  useEffect(() => {
    const fkey = formData.formKey || defaultFormKey;

    let currentValues: FormValueParams = {};

    if (fkey === formKey) currentValues = { ...formValues[fkey] };
    else setFormKey(fkey);

    if (formData && formData.fields && formData.fields.length && formData.fields !== formFields) {
      formData.fields.forEach((field) => {
        if (field.fieldName && !currentValues[field.fieldName]) {
          currentValues = { ...currentValues, [field.fieldName]: field.defaultValue as string };
        }
      });

      setFormFields(formData.fields);

      setFormValues((currentState) => {
        return { ...currentState, [fkey]: { ...currentValues } };
      });
      setFormFields(formData.fields);
    }
  }, [formData.fields]); //eslint-disable-line

  const formSubmitButtonHandler = useCallback(() => {
    let hasError = false;
    let errorFields: string[] = [];

    const fkey = formData.formKey || defaultFormKey;

    formFields.forEach((item) => {
      if (!item) return null;

      if (item.required && item.fieldType !== 'hidden') {
        hasError = true;

        if (item.fieldName && item.fieldName in formValues[fkey]) {
          Object.entries(formValues[fkey]).forEach((entry) => {
            if (entry[0] === item.fieldName) {
              if (entry[1]) {
                hasError = false;
              }
            }
          });

          if (
            item.fieldType === 'password' &&
            formValues[fkey][item.fieldName] &&
            formValues[fkey][`${item.fieldName}2`] &&
            formValues[fkey][item.fieldName] !== formValues[fkey][`${item.fieldName}2`]
          ) {
            hasError = true;
          }

          hasError && item.fieldName && errorFields.push(item.fieldName);

          setFieldsWithError(errorFields);
        }
      }
    });

    if (!errorFields.length) {
      if (formData && formData.submitUrl) {
        setSending(true);
        submitForm(formData.submitUrl, formValues[fkey], setFormResponse);
      } else if (callBack) callBack(formValues[fkey], clearForm);
    }
  }, [formFields, formValues, formData, callBack, clearForm]);

  if (sending)
    return (
      <Block textPosition="center">
        <FormResponseText>Sending...</FormResponseText>
      </Block>
    );

  if (formResponse.responseText && !formResponse.errorMessage) {
    return (
      <Block textPosition="center">
        <FormResponseText>{formResponse.responseText}</FormResponseText>
      </Block>
    );
  }

  return (
    <Block textPosition="left">
      {formResponse.errorMessage ? <FormErrorMessage>{formResponse.errorMessage}</FormErrorMessage> : null}
      {formFields.map((item, index) => {
        const fkey = formData.formKey || defaultFormKey;
        if (!item || item.fieldType === 'hidden') return null;

        if (item.component) return <div key={String(index)}>{item.component}</div>;

        if (item.fieldType === 'line') return <LineBreak key={String(index)} />;

        let errorMessage: string =
          item.fieldName && fieldsWithError?.includes(item.fieldName) ? Text.REQUIRED_FIELD : '';

        if (
          !errorMessage &&
          item.fieldType === 'password' &&
          item.fieldName === 'password2' &&
          formValues[fkey][item.fieldName] &&
          formValues[fkey]['password'] &&
          formValues[fkey][item.fieldName] !== formValues[fkey]['password']
        )
          errorMessage = Text.PASSWORDS_NOT_MATCH;

        return (
          <FormFieldBox
            key={String(index)}
            width={item.size === 'half' ? '50%' : item.size === 'quarter' ? '25%' : '100%'}
            error={errorMessage}
            positionRight={item.positionRight}
          >
            {item.fieldType !== 'checkbox' && (
              <FieldLabel required={item.required ? item.required : false}>{item.label}</FieldLabel>
            )}
            <FormFieldContainer positionRight={item.positionRight}>
              {item.prefixText}

              <FormField
                fieldName={item.fieldName}
                placeholder={item.placeholder}
                fieldType={item.fieldType}
                required={item.required}
                defaultValue={item.defaultValue}
                enteredValue={
                  item.fieldName && formValues[fkey] && formValues[fkey][item.fieldName]
                    ? formValues[fkey][item.fieldName]
                    : ''
                }
                values={item.values}
                size={item.size}
                fieldValueHandler={fieldValueHandler}
                formKey={fkey}
                formValues={formValues}
                inputManipulator={item.inputManipulator}
                fieldsWithError={fieldsWithError}
                setFieldsWithError={setFieldsWithError}
                onClick={item.onClick}
                onChange={item.onChange}
                editablePreview={item.editablePreview}
                editableCallback={item.editableCallback}
                editableError={item.editableError}
              />
              {item.fieldType === 'checkbox' && (
                <FieldLabel required={item.required ? item.required : false}>{item.label}</FieldLabel>
              )}
              {item.buttonCallback && item.buttonLabel && (
                <FormButton onClick={item.buttonCallback}>{item.buttonLabel}</FormButton>
              )}
            </FormFieldContainer>
            {errorMessage && <FieldErrorMessage>{errorMessage}</FieldErrorMessage>}
          </FormFieldBox>
        );
      })}
      <ButtonContainer
        sinlgeButton={formData && formData.secondButtonLabel && formData.secondButtonLabel !== undefined ? false : true}
      >
        {formData.buttonLabel && (
          <FormButton onClick={formSubmitButtonHandler} active={true}>
            {formData && formData.buttonLabel !== undefined ? formData.buttonLabel : Text.SUBMIT}
          </FormButton>
        )}
        {formData && formData.secondButtonLabel && formData.secondButtonCallback ? (
          <FormButton
            onClick={() => formData.secondButtonCallback && formData.secondButtonCallback(formValues[formKey])}
            active={false}
            style={{ marginLeft: 20 }}
          >
            {formData.secondButtonLabel}
          </FormButton>
        ) : null}
      </ButtonContainer>
    </Block>
  );
};
