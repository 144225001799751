export const LOGIN = 'Login';
export const PASSWORD = 'Password';
export const EMAIL = 'E-mail address';
export const FORGOTTEN_PASSWORD = 'Forgot your password?';
export const SEND_CONFIRMATION_CODE = 'Send a confirmation code';
export const CANCEL = 'Cancel';

export const CONFIRMATION_CODE = 'Confirmation code';
export const RESEND_CODE = 'Send me a new code';
export const CONFIRM = 'Confirm';
export const CODE_SENT = 'The confirmation code has been sent.';
