import styled from 'styled-components';

export const Center = styled.div<{ css?: string }>`
  text-align: center;
  ${(p) => p.css};
`;

export const Right = styled.div<{ css?: string }>`
  text-align: right;
  ${(p) => p.css};
`;

export const LeftAndRightText = styled.div<{ css?: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
