import styled from 'styled-components';

export const Img = styled.img`
  max-width: 100%;
`;

export const FileInputContainer = styled.div`
  display: none;
`;
export const FileInput = styled.input``;
