import { FC, useCallback, useState } from 'react';
import { Form, api, Box } from '../../components';
import { useUserContext } from '../../context';
import { Login as Text, Error } from '../../language';

export const ConfirmationCode: FC = () => {
  const [formError, setFormError] = useState('');
  const [, setStore] = useUserContext();

  const checkConfirmationCode = useCallback(
    (formData) => {
      api('user/checkConfirmationCode', { confirmationCode: formData.confirmationCode } , (data) => {
        setFormError('');
        if (data.error) setFormError(Error(data.error));
        else if (data.status === 'ok' && data.userData) {
          console.log(data);
          setStore({ ...data.userData, confirmationCode: formData.confirmationCode });
        }
      });
    },
    [setStore],
  );

  const sendConfirmationCode = useCallback(() => {
    api('user/sendConfirmationCode', {}, (data) => {
        if (data.error) setFormError(Error(data.error));
		else 
      setFormError(Text.CODE_SENT);
    });
  }, []);

  return (
    <Box title={Text.CONFIRMATION_CODE} error={formError} centeredTitle={true}>
      <Form
        callBack={checkConfirmationCode}
        formData={{
          buttonLabel: Text.CONFIRM,
          secondButtonLabel: Text.RESEND_CODE,
          secondButtonCallback: sendConfirmationCode,
          fields: [
            {
              label: Text.CONFIRMATION_CODE,
              fieldName: 'confirmationCode',
              fieldType: 'text',
              required: true,
            },
          ],
        }}
      />
    </Box>
  );
};
