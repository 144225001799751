import { FC, useState, useEffect, useMemo } from 'react';
import {
  NavigationContainer,
  NavItems,
  NavItem,
  NavLink,
  NavBar,
  Hamburger,
  Logo,
  NavigationContainerBody,
  LoggedUser,
} from './Navigation.style';
import { Link } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import SvgLogo from '../../assets/qrcodesone.svg';
import { navigationData, navigationDataLoggedIn } from '../../language';
import { useUserContext } from '../../context/userContext';
import { NavigationProps } from '../../types';

export const Navigation: FC<{ noBorder?: boolean }> = ({ noBorder }) => {
  const [store] = useUserContext();
  const [navData, setNavData] = useState<NavigationProps>();
  const [mobileNavOpened, setMobileNavOpened] = useState<boolean>(false);

  useEffect(() => {
    setNavData(store.logged ? (navigationDataLoggedIn as NavigationProps) : (navigationData as NavigationProps));
  }, [store.logged]);

  const loggedUser = useMemo(
    () => (
      <LoggedUser>
        {store.profile?.firstName} {store.profile?.lastName}{' '}
        {store.profile?.credit && `, credit: ${store.profile.credit}€`}
      </LoggedUser>
    ),
    [store.profile],
  );


  return (
    <NavigationContainer noBorder={noBorder}>
      <NavigationContainerBody mobile={mobileNavOpened}>
        <Logo>
          <Link to="/">
            <SVG className="logo" src={SvgLogo} />
          </Link>
        </Logo>
        <NavBar mobile={mobileNavOpened}>
          {loggedUser}
          <NavItems>
            {navData &&
              navData.links &&
              navData.links.map((item, index) => (
                <NavItem key={String(index)}>
                  <NavLink onClick={() => setMobileNavOpened(false)} to={`${item.url}`} selected={false}>
                    {item.title}
                  </NavLink>
                </NavItem>
              ))}
          </NavItems>
        </NavBar>

        <Hamburger scrolly={1} active={mobileNavOpened} onClick={() => setMobileNavOpened(!mobileNavOpened)}>
          <div></div>
          <div></div>
        </Hamburger>
      </NavigationContainerBody>
    </NavigationContainer>
  );
};
