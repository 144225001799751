import styled from 'styled-components';
import { COLORS } from '../../constants/colors';

export const BoxContainer = styled.div`
  box-shadow: 1px 1px 10px #00000020;
  // border: 5px solid ${COLORS.boxBorderColor};
  border-radius: 5px;
  margin: 20px;
  background-color: ${COLORS.boxBackgroundColor};
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`;
export const BoxSection = styled.div<{ align?: string; flex?: number }>`
  padding: 20px;
  text-align: ${(props) => props.align || 'left'};
  flex: ${(props) => props.flex || 'auto'};
`;

export const BoxTitleContainer = styled.div<{ centered?: boolean }>`
  ${(props) => props.centered && 'text-align: center;'}
`;

export const BoxTitle = styled.div`
  font-size: 1.4rem;
  font-weight: 450;
  margin-bottom: 10px;
`;

export const BoxSubtitle = styled.div`
  color: ${COLORS.subtitleColor};
  text-transform: uppercase;
  font-size: 0.8rem;
  margin: 10px 0 5px 0;
`;

export const BoxError = styled.div`
  color: ${COLORS.errorRed};
  font-size: 1rem;
  margin: 10px 0 5px 0;
`;
export const BoxText = styled.div<{ centered?: boolean }>`
  ${(props) => props.centered && 'text-align: center;'}
`;

export const BoxImage = styled.img`
  border-radius: 100%;
  height: 100px;
  width: 100px;
  max-width: 100%;
  object-fit: cover;
`;

export const LineBreak = styled.div`
  width: 20px;
  margin: 15px 0 25px 0;
  height: 1px;
  background-color: ${COLORS.primaryColor};
`;
