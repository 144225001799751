import { API_URL } from '../../constants/config';
import Cookies from 'universal-cookie';
import { UserStateType } from '../../types';
import { Error } from '../../language';

interface ApiReturnData {
  status: string;
  alert?: string;
  log?: string;
  error?: string;
  token?: string;
  message?: string;
  redirect?: string;
  data?: {} | {}[];
  record?: {} | {}[];
  val?: string;
  userData?: UserStateType;
}



export const api = async (action: string, data: {}, returnData: (data: ApiReturnData) => void) => {
  try {
    const cookies = new Cookies();

    const server = API_URL + action;


    //console.log({ ...requestData, token: cookies.get('token') });

    await fetch(server, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        //"Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        data,
        token: cookies.get('token'),
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data: ApiReturnData) => {
        if (data && typeof data === 'object') {
          if (data.alert) alert(data.alert);

          if (data.log) console.log(data.log);

          if (action === 'logout' || data.token === '-1') {
            cookies.remove('token');
          } else if (data.token) {
            cookies.set('token', data.token, { path: '/', maxAge: 2592000 });
          }

          if (data.redirect) {
            window.location.href = data.redirect;
          }

          returnData({ ...data, error: data.error && Error(data.error) });
        }
      });
  } catch (e) {
    console.log(e);
  }
};
