import { FC } from 'react';
import { Service, ServiceTitle, ServiceDetails, ServiceInfo, ServicePrice, ServiceHeader } from './Option.style';
import { FormButton } from '../styled';

export const Option: FC<{
  title: string;
  description?: string | JSX.Element;
  avatar?: JSX.Element;
  label?: string;
  header?: string;
  onClick?: () => void;
  buttons?: {
    title: string;
    onClick: () => void;
    active?: boolean;
  }[];
  active?: boolean;
  noBorder?: boolean;
}> = ({ title, description, label, onClick, buttons, header, active, avatar, noBorder }) => {
  return (
    <Service onClick={onClick} cursorPointer={!!onClick} active={active} noBorder={noBorder}>
      {avatar && avatar}
      <ServiceInfo>
        {header && <ServiceHeader>{header}</ServiceHeader>}
        <ServiceTitle>{title}</ServiceTitle>
        {description && <ServiceDetails>{description}</ServiceDetails>}
      </ServiceInfo>
      {label && <ServicePrice>{label}</ServicePrice>}
      {buttons?.map((item, index) => (
        <FormButton key={String(index)} onClick={item.onClick} active={item.active} margin="0 0 0 10px">
          {item.title}
        </FormButton>
      ))}
    </Service>
  );
};
