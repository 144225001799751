import { useCallback, FC, useMemo, useState, useEffect } from 'react';
import { FormFieldType, FormFieldSelectValues } from '../../types';
import { Input, Textarea, Select, Checkbox, EditableError, EditablePreview } from '../styled/Form.style';
import { RichText } from './Wysiwyg';
import { FormButton, SmallButton } from '../styled';
import * as Text from '../../language/form.lang';

export interface FormFieldData {
  fieldName: string;
  fieldValue: string;
}

interface FormFieldDisplay extends FormFieldType {
  //setFormValues: (value: {}) => void;
  fieldValueHandler: (fieldName: string, value: string) => void;
  fieldsWithError: string[];
  setFieldsWithError: (values: string[]) => void;
  formValues: {};
  enteredValue: string;
  formKey: string;
}

export const FormField: FC<FormFieldDisplay> = ({
  fieldName,
  placeholder,
  fieldType = 'text',
  required,
  values,
  size,
  fieldValueHandler,
  fieldsWithError,
  setFieldsWithError,
  enteredValue,
  onClick,
  editablePreview,
  editableCallback,
  editableError,
  defaultValue,
  onChange,
  formValues,
  inputManipulator,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [displayEditableError, setDisplayEditableError] = useState('');

  useEffect(() => {
    editableError && setDisplayEditableError(editableError);
  }, [editableError]);

  // const updateFormValue = useCallback(
  //   (fieldName: string, value: string) => {
  //     fieldValueHandler(fieldName, value);
  //   },
  //   [fieldValueHandler],
  // );

  const setEnteredValue = useCallback(
    (val: string) => {
      const value = inputManipulator ? inputManipulator(val) : val;

      fieldName && fieldValueHandler(fieldName, value);

      //clear the error message
      if (required && enteredValue && fieldName && fieldsWithError?.includes(fieldName)) {
        let errorFields: string[] = [];

        errorFields = fieldsWithError?.filter((item: string) => item !== fieldName);
        setFieldsWithError(errorFields);
      }
    },
    [inputManipulator, fieldName, fieldValueHandler, required, enteredValue, fieldsWithError, setFieldsWithError],
  );

  const cancelEditHandler = useCallback(() => {
    setEditMode(false);
    setEnteredValue(defaultValue || '');
    setDisplayEditableError('');
  }, [defaultValue, setEnteredValue]);

  const changeValueHandler = useCallback(
    (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
      onChange && onChange(event.currentTarget.value);
      setEnteredValue(event.currentTarget.value);
    },
    [onChange, setEnteredValue],
  );

  const Field = useMemo(() => {
    switch (fieldType) {
      case 'text': {
        return (
          <Input
            type="text"
            placeholder={placeholder}
            required={required}
            width={size}
            name={fieldName}
            value={enteredValue}
            onChange={(e) => changeValueHandler(e)}
          />
        );
      }

      case 'button': {
        return <FormButton onClick={onClick}>{fieldName}</FormButton>;
      }

      case 'password': {
        return (
          <Input
            type="password"
            placeholder={placeholder}
            required={required}
            width={size}
            name={fieldName}
            value={enteredValue}
            onChange={(e) => changeValueHandler(e)}
          />
        );
      }

      case 'email': {
        return (
          <Input
            type="email"
            placeholder={placeholder}
            required={required}
            width={size}
            name={fieldName}
            value={enteredValue}
            onChange={(e) => changeValueHandler(e)}
          />
        );
      }
      case 'date': {
        return (
          <Input
            type="date"
            placeholder={placeholder}
            required={required}
            width={size}
            name={fieldName}
            value={enteredValue}
            onChange={(e) => changeValueHandler(e)}
          />
        );
      }
      case 'number': {
        return (
          <Input
            type="number"
            placeholder={placeholder}
            required={required}
            width={size}
            name={fieldName}
            value={enteredValue}
            onChange={(e) => changeValueHandler(e)}
          />
        );
      }
      case 'textarea': {
        return (
          <Textarea
            placeholder={placeholder}
            required={required}
            name={fieldName}
            onChange={(e) => changeValueHandler(e)}
            value={enteredValue}
          />
        );
      }
      case 'checkbox': {
        return (
          <Checkbox
            type="checkbox"
            required={required}
            name={fieldName}
            value="true"
            checked={enteredValue ? true : false}
            onChange={(e) => {
              setEnteredValue(e.target.checked ? '1' : '');
              onChange && onChange(e.target.checked ? '1' : '');
            }}
            //onClick={() => setEnteredValue(enteredValue ? '' : '1')}
          />
        );
      }
      case 'richtext': {
        return <RichText initialValue={enteredValue} setEnteredValue={setEnteredValue} />;
      }
      case 'select': {
        const selectValues: FormFieldSelectValues[] =
          values && values.length && typeof values[0] === 'string'
            ? values.map((item, index) => {
                return { key: String(index) as string, value: item as string };
              })
            : (values as FormFieldSelectValues[]);

        return (
          <Select value={enteredValue} required={required} name={fieldName} onChange={(e) => changeValueHandler(e)}>
            {selectValues.map((item, index) => {
              return (
                <option key={String(index)} value={item.key}>
                  {item.value}
                </option>
              );
            })}
          </Select>
        );
      }

      default: {
        return (
          <Input
            type="text"
            placeholder={placeholder}
            required={required}
            width={size}
            name={fieldName}
            value={enteredValue}
            onChange={(e) => changeValueHandler(e)}
          />
        );
      }
    }
  }, [
    changeValueHandler,
    enteredValue,
    fieldName,
    fieldType,
    onChange,
    onClick,
    placeholder,
    required,
    setEnteredValue,
    size,
    values,
  ]);

  useEffect(() => {
    console.log(editMode);
  }, [editMode]);

  if (editablePreview) {
    if (editMode && editableCallback) {
      return (
        <>
          {Field}
          <>
            <FormButton onClick={cancelEditHandler}>{Text.CANCEL}</FormButton>
            <FormButton
              onClick={() => {

                if (required && !enteredValue) {
                  setDisplayEditableError(Text.REQUIRED_FIELD);
                } else {
                  setDisplayEditableError('');
                  editableCallback(setEditMode, enteredValue, fieldName);
                }
              }}
              active={true}
            >
              {Text.SAVE}
            </FormButton>
          </>
          {displayEditableError && <EditableError>{displayEditableError}</EditableError>}
        </>
      );
    }

    return (
      <>
        <EditablePreview>{enteredValue}</EditablePreview>
        <SmallButton onClick={() => setEditMode(true)}>{enteredValue ? Text.EDIT : Text.ADD}</SmallButton>
      </>
    );
  }

  return <>{Field}</>;
};
