export const submitForm = (
  serverUrl: string,
  formData: {},
  onResponse: (data: { responseText: string; errorMessage: string }) => void,
) => {
  try {
    fetch(serverUrl, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify({
        ...formData,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data && typeof data === 'object') {
          if (data.log) console.log(data.log);

          const responseText = {
            responseText: data.responseText || '',
            errorMessage: data.errorMessage || '',
          };

          return onResponse(responseText);
        }
      });
  } catch (e) {
    console.log(e);
  }
};
