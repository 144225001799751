import { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { useUserContext } from './context/userContext';
import * as Screen from './screens';
import * as Account from './screens/Account';
import { api, Logout, Page } from './components';

const App = () => {
  const [store, setStore] = useUserContext();

  useEffect(() => {
    const cookies = new Cookies();
    if (!store.token && cookies.get('token')) {
      api('user', {}, (data) => {
        if (data.userData) setStore({ status: 'init', ...data.userData });
		else 
		setStore({ status: 'init' });
      });
    } else {
      setStore({ status: 'init' });
    }
  }, []); //eslint-disable-line

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Screen.Homepage />
        </Route>

        <Route exact path="/dashboard">
          <Page centered={!store.logged}>{store.logged ? <Account.Posts /> : <Screen.Login />}</Page>
        </Route>
        <Route exact path="/settings">
          <Page centered={true}>{store.logged ? <Account.Settings /> : <Screen.Login />}</Page>
        </Route>

        <Route exact path="/password">
          <Page centered={true}>{store.logged ? <Account.SetPassword /> : <Screen.Login />}</Page>
        </Route>
        <Route exact path="/signup">
          {store.logged ? (
            <Redirect to="/dashboard" />
          ) : store.status === 'confirmationCode' ? (
            <Page centered={true}>
              <Screen.ConfirmationCode />
            </Page>
          ) : (
            <Page centered={true}>
              <Screen.Signup />
            </Page>
          )}
        </Route>
        <Route exact path="/login">
          <Page centered={true}>
            <Screen.Login />
          </Page>
        </Route>

        <Route exact path="/logout" component={Logout} />
      </Switch>
    </Router>
  );
};

export default App;
