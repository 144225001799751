import styled, { css } from 'styled-components';
import { COLORS } from '../../constants/colors';

export const ModalContainer = styled.div<{ isopen: boolean }>`
  position: fixed;
  overflow: hidden;
  top: ${(props) => (props.isopen ? '0' : '-1000px')};
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.65);
  width: ${(props) => (props.isopen ? '100%' : '0')};
  height: ${(props) => (props.isopen ? '100%' : '0')};
  display: flex;
  opacity: ${(props) => (props.isopen ? '1' : '0')};
  align-items: center;
  justify-content: center;
  padding: 1.5em 1em;
  z-index: 999;
  box-sizing: border-box;
  transition: opacity 0.5s;
`;

export const ModalOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

export const ModalContent = styled.div`
  width: 600px;
  max-width: 100%;
`;

export const ModalBody = styled.div`
  z-index: 2;
  position: relative;
  margin: 0 auto;
  background-color: ${COLORS.boxBackgroundColor};
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 8px;
  max-height: 100%;
  max-width: 100%;
  padding: 0px 30px 20px 30px;
  color: #c3c0c0;
  overflow-x: hidden;
  overflow-y: auto;
`;
export const ModalTitleBar = styled.div`
  display: flex;
  position: sticky;
  background-color: ${COLORS.boxBackgroundColor};
  padding-top: 10px;
  top: 0;
`;

const btnStyle = css`
  padding: 3px;
  z-index: 999;
  display: inline-block;
  line-height: 1.5rem;

  transition: 0.1s;
  box-sizing: border-box;
  color: #333;
  font-size: 1rem;
  width: 32px;
  min-height: 32px;
  display: flex;
  align-items: center;
`;

export const ModalTitle = styled.div`
  flex: 1;
  color: ${COLORS.titleColor};
  font-weight: 600;

  ${btnStyle}
`;

export const ModalBackButtonDisabled = styled.span``;

export const ModalBackButton = styled.span`
  cursor: pointer;
  font-weight: bold;
  ${btnStyle}
  &:hover {
    font-size: 1.5rem;
  }
`;

export const ModalClose = styled.span`
  //position: fixed;
  margin-right: -18px;
  justify-content: center;

  cursor: pointer;
  ${btnStyle}
  &:hover {
    font-size: 1.5rem;
  }
`;
