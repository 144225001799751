import { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';

import { useUserContext } from '../../context';
import { api } from '../';

export const Logout = () => {
  const [, setStore] = useUserContext();

  useEffect(() => {
  const cookies = new Cookies();

    api('logout', {}, (data) => {
      cookies.remove('token');
      setStore({ logged: false, status: 'init', profile: {} });
    });
  }, [ setStore]);

  return <Redirect to="/login" />;
};
