import { FC, useCallback, useState } from 'react';
import { Redirect } from 'react-router-dom';

import { Form, api, Box } from '../../components';
import { useUserContext } from '../../context';
import { Login as Text, Error } from '../../language';
import { SetPasswordConfirmationCode } from './SetPasswordConfirmationCode';
import { ConfirmationCode } from '../';

export const Login: FC<{ modalWindow?: boolean }> = ({ modalWindow }) => {
  const [formError, setFormError] = useState('');
  const [forgottenPasswordForm, setForgottenPassworForm] = useState(false);
  const [store, setStore] = useUserContext();
  const [userStatus, setUserStatus] = useState('');

  const loginHandler = useCallback(
    (formData, clearForm?) => {
      api('user/login', formData , (data) => {
		setUserStatus(data.status);

        if (data.error) setFormError(Error(data.error));
        else {
          setFormError('');
          setStore({ ...data.userData });
        }
      });
    },
    [setStore],
  );

	if(!store.status) {
		return null;
	}



  if (forgottenPasswordForm) {
    return <SetPasswordConfirmationCode setForgottenPassworForm={setForgottenPassworForm} />;
  }


  if (store.logged) {
    return <Redirect to="/dashboard" />;
  }

  if (store.status === 'confirmationCode' || userStatus === 'confirmationCode') {
    return <ConfirmationCode />;
  }

  return (
    <Box title={Text.LOGIN} error={formError} centeredTitle={true}>
      <Form
        callBack={loginHandler}
        formData={{
          buttonLabel: Text.LOGIN,
        //  secondButtonLabel: Text.FORGOTTEN_PASSWORD,
          secondButtonCallback: () => setForgottenPassworForm(true),
          fields: [
            {
              label: Text.EMAIL,
              fieldName: 'email',
              fieldType: 'email',
              required: true,
            },
            // {
            //   label: Text.PASSWORD,
            //   fieldName: 'password',
            //   fieldType: 'password',
            //   required: true,
            // },
          ],
        }}
      />
    </Box>
  );
};
