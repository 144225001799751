import React, { FC, useCallback, useEffect } from 'react';
import {
  ModalContainer,
  ModalBody,
  ModalClose,
  ModalOverlay,
  ModalTitle,
  ModalTitleBar,
  ModalBackButton,
  ModalBackButtonDisabled,
  ModalContent,
} from './Modal.style';

interface SectionParams {
  children: React.ReactNode;
  isOpen: boolean;
  setIsOpen?: (status: boolean) => void;
  onClose?: () => void;
  escKey?: boolean;
  title?: string | undefined;
  backButtonCallback?: () => void;
}

export const Modal: FC<SectionParams> = ({
  children,
  isOpen = false,
  setIsOpen,
  onClose,
  escKey = true,
  title,
  backButtonCallback,
}) => {
  const close = useCallback(() => {
    setIsOpen && setIsOpen(false);
    if (onClose) onClose();
  }, [onClose, setIsOpen]);

  const handleEscape = useCallback(
    (event) => {
      if (event.keyCode === 27) close();
    },
    [close],
  );

  useEffect(() => {
    if (escKey) {
      if (isOpen) document.addEventListener('keydown', handleEscape, false);

      return () => {
        if (isOpen) document.addEventListener('keydown', handleEscape, false);
      };
    }
  }, [handleEscape, isOpen, escKey]);

  return (
    <ModalContainer isopen={isOpen}>
      <ModalOverlay onClick={close} />
      <ModalBody>
        <ModalTitleBar>
          {backButtonCallback ? (
            <ModalBackButton onClick={backButtonCallback}>⟨</ModalBackButton>
          ) : (
            <ModalBackButtonDisabled />
          )}
          <ModalTitle>{title}</ModalTitle>
          <ModalClose onClick={close}>✕</ModalClose>
        </ModalTitleBar>
        <ModalContent>{isOpen && children}</ModalContent>
      </ModalBody>
    </ModalContainer>
  );
};
