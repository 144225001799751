import { useMemo, useCallback, useState } from 'react';
import { Box, Form, api } from '../../../components';
//import { Button, Center } from '../../../components/styled';
import { Account as Text, Error } from '../../../language';
import { useUserContext } from '../../../context';
import { FormFieldType, KeyValueType } from '../../../types';
import { ProfilePhoto } from './ProfilePhoto';

export const Settings = () => {
  const [store, setStore] = useUserContext();
  const [formError, setFormError] = useState<KeyValueType>({});

  const saveHandler = useCallback(
    (setEditMode, value: string, fieldName: string) => {

      api('user/updateProfile', { [fieldName]: value } , (data) => {
        setFormError({ ...formError, [fieldName]: data.error ? Error(data.error) : '' });

        if (!data.error) {
          setStore({ ...store, profile: { ...store.profile, [fieldName]: value} });
          setEditMode(false);
        }
      });
    },
    [formError, setStore, store],
  );

  const fields = useMemo(() => {
    return [
      // {
      //   label: Text.USERNAME,
      //   fieldName: 'username',
      //   defaultValue: store.profile?.username,
      //   required: true,
      //   editableError: formError['username'],
      //   editablePreview: true,
      //   editableCallback: saveHandler,
      // },
      {
        label: Text.FIRST_NAME,
        fieldName: 'firstName',
        defaultValue: store.profile?.firstName,
        required: true,
        editableError: formError['firstName'],
        editablePreview: true,
        editableCallback: saveHandler,
      },
      {
        label: Text.LAST_NAME,
        fieldName: 'lastName',
        defaultValue: store.profile?.lastName,
        required: true,
        editableError: formError['lastName'],
        editablePreview: true,
        editableCallback: saveHandler,
      },
      {
        label: Text.COMPANY_NAME,
        fieldName: 'companyName',
        defaultValue: store.profile?.companyName,
        required: false,
        editableError: formError['companyName'],
        editablePreview: true,
        editableCallback: saveHandler,
      },
      {
        label: Text.PROFILE_TITLE,
        fieldName: 'title',
        defaultValue: store.profile?.title,
        required: false,
        editableError: formError['title'],
        editablePreview: true,
        editableCallback: saveHandler,
      },
    ] as FormFieldType[];
  }, [formError, saveHandler, store.profile]);

  return (
    <Box title={Text.SETTINGS} centeredTitle={true}>
      <ProfilePhoto />
      <Form
        formData={{
          fields: [...fields],
        }}
      />
      {/* <Center>
        <Button to="/password">{Text.CHANGE_PASSWORD}</Button>
      </Center> */}
    </Box>
  );
};
