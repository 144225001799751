import { KeyValueType } from '../types';

export const COPYRIGHT = 'Developed by {brackets.studio}';
export const COPYRIGHT_LINK = 'https://brackets.studio';

export const navigationData = {
  links: [
    {
      title: 'My QR Codes',
      url: '/login',
    },
    {
      title: 'Free registration',
      url: '/signup',
    },
    {
      title: 'Login',
      url: '/login',
    },
  ],
};

export const navigationDataLoggedIn = {
  links: [
    {
      title: 'My QR codes',
      url: '/dashboard',
    },
    {
      title: 'Settings',
      url: '/settings',
    },
    {
      title: 'Logout',
      url: '/logout',
    },
  ],
};
export const DELETE = 'Delete';
export const LOGIN = 'Login';
export const NEXT = 'Continue »';

export const ERROR: KeyValueType = {
  ERR_INVALID_CONFIRMATION_CODE: 'Entered code is not valid.',
  ERR_INVALID_USER: 'Invalid user or password.',
  ERR_INVALID_PASSWORD: 'Invalid user or password.',
  ERR_TITLE_EXISTS: 'This title already exists.',
  ERR_RECORD_EXISTS: 'This record alredy exists.',
  ERR_EMAIL_EXISTS: 'This email address alredy exists.',
  ERR_PASSWORDS_NOT_MATCH: 'The entered password do not match.',
};

export const Error = (str: string) => {
  return ERROR[str] || str;
};
