import styled from 'styled-components';
import { COLORS } from '../../constants/colors';

export const ButtonContainer = styled.div<{ sinlgeButton: boolean }>`
  padding: 20px 10px;
  display: flex;
  justify-content: ${(props) => (props.sinlgeButton ? 'center' : 'space-between')};
  & > button {
    ${(props) => props.sinlgeButton && 'margin: 0 10px;'};
    //width: ${(props) => (props.sinlgeButton ? '100%' : 'auto')};
  }
`;

export const FormFieldContainer = styled.div<{ positionRight?: boolean }>`
  display: flex;

  ${(params) => (params.positionRight ? 'justify-content: right;' : 'align-items: center;')}
  & > button {
    margin-left: 10px;
    max-height: 35px;
  }
`;

export const Input = styled.input`
  border: 1px ${COLORS.boxBorderColor} solid;
  border-radius: 3px;
  color: ${COLORS.textColor};
  background: none;
  font-size: 1rem;
  padding: 16px;
  box-sizing: border-box;
  width: 100%;
`;

export const Textarea = styled.textarea`
  border: 1px ${COLORS.boxBorderColor} solid;
  border-radius: 3px;
  color: ${COLORS.textColor};
  background: none;
  font-size: 1rem;
  padding: 16px;
  box-sizing: border-box;
  height: 120px;
  width: 100%;
`;

export const Select = styled.select`
  border: 1px ${COLORS.boxBorderColor} solid;
  border-radius: 3px;
  color: ${COLORS.textColor};
  background-color: ${COLORS.backgroundColor};
  background-image: url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='16' height='16' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='gray'/></g></svg>");

  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: center;
  font-size: 1rem;
  padding: 16px;
  box-sizing: border-box;
  width: 100%;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
`;

export const Checkbox = styled.input`
  background: red;
  &:checked {
    background: blue;
  }
`;

export const FieldLabel = styled.div<{ required: boolean }>`
  color: ${COLORS.textColor};
  font-size: 0.9rem;
  font-weight: 600;
  display: block;
  margin-bottom: 5px;
  &::after {
    content: '*';
    display: ${(props) => (props.required ? 'inline-block' : 'none')};
    margin-left: 5px;
    color: ${COLORS.errorRed};
  }
`;

export const FormFieldBox = styled.div<{
  width: string;
  error: string | undefined;
  positionRight?: boolean;
}>`
  width: ${(props) => props.width};
  display: inline-block;
  padding: 7px 10px;
  box-sizing: border-box;
  ${(params) => params.positionRight && 'text-align: right;'}
  & > input,
  textarea,
  select {
    margin-top: 6px;
    ${(props) => (props.error ? 'border: 1px #F46165 solid;' : null)};
  }
  & > input:focus,
  textarea:focus,
  select:focus {
    border-color: ${COLORS.formBorderActiveColor};
    outline: none;
  }
  & > input[type='checkbox'] {
    float: left;
    margin-right: 5px;
    margin-top: 4px;
  }
  @media (max-width: 600px) {
    & {
      width: 100%;
    }
  }
`;

export const FieldErrorMessage = styled.div`
  color: ${COLORS.errorRed};
  padding-top: 3px;
  font-size: 0.8rem;
  float: right;
`;

export const FormErrorMessage = styled.div`
  color: ${COLORS.errorRed};
  padding-bottom: 20px;
  font-size: 1.2rem;
  text-align: center;
`;

export const FormResponseText = styled.div`
  font-size: 3rem;
  color: ${COLORS.textColor};
  text-align: center;
`;

export const EditableError = styled.div`
  color: ${COLORS.errorRed};
  font-size: 0.5rem;
  font-weight: bold;
  position: absolute;
  margin-top: 25px;
  margin-left: 18px;
`;

export const EditablePreviewContainer = styled.div``;
export const EditablePreview = styled.div`
  white-space: pre;
  background: #f9f9f9;
  padding: 3px 0;
`;
