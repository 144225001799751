import { FC } from 'react';
import { Container, Footer } from './Page.style';
import { Navigation } from '../';
import { COPYRIGHT, COPYRIGHT_LINK } from '../../language/language';

import GlobalStyle from '../styled/Global.style';
export const Page: FC<{ noBorder?: boolean; centered?: boolean }> = ({ children, noBorder, centered }) => {
  return (
    <>
      <GlobalStyle />
      <Navigation noBorder={noBorder} />
      <Container centered={centered}>
        <div>{children}</div>
      </Container>
      <Footer>
        <a href={COPYRIGHT_LINK} rel="noreferrer" target="_blank">
          {COPYRIGHT}
        </a>
      </Footer>
    </>
  );
};
