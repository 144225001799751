import styled from 'styled-components';
import { COLORS } from '../../constants/colors';

export const QuillContainer = styled.div`
  width: 100%;
  & .ql-editor {
    color: ${COLORS.textColor};
    font-size: 1rem;
  }
`;
