import React from 'react';
import { UserStateType } from '../types';

const initialState: UserStateType = {};

// Global app context.
type StateT = typeof initialState;
type StateGetSetT = [StateT, React.Dispatch<React.SetStateAction<StateT>>];

const UserContext = React.createContext<StateGetSetT | undefined>(undefined);

// Context provider, should wrap entire application.
type UserContextProviderProps = {
  children: React.ReactNode;
};

const UserContextProvider: React.FC<UserContextProviderProps> = ({ children }) => {
  const contextGetSet = React.useState(initialState);
  return <UserContext.Provider value={contextGetSet}>{children}</UserContext.Provider>;
};

// Custom hook to retrieve and set context state.
type SetPartialStateT = (newVals: Partial<StateT>) => void;
type UseMyContextT = [StateT, SetPartialStateT];

const useUserContext = (): UseMyContextT => {
  const [state, setState] = React.useContext(UserContext) as StateGetSetT;

  const setPartialState = (newVals: Partial<StateT>) => {
    setState({ ...state, ...newVals });
  };

  return [state, setPartialState];
};

export { UserContextProvider, useUserContext };
