import { FC, useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { QuillContainer } from './wysiwyg.style';

export const RichText: FC<{ initialValue: string; setEnteredValue: (data: string) => void }> = ({
  initialValue,
  setEnteredValue,
}) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setEnteredValue(value);
  }, [setEnteredValue, value]);

  return (
    <QuillContainer>
      <ReactQuill theme="snow" value={value} onChange={setValue} />
    </QuillContainer>
  );
};
