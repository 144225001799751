import { FC } from 'react';
import QRCode from 'qrcode.react';
import { PostType } from '../../types';
import { Option } from '../';
import { saveAs } from 'file-saver';

export const ExportQRCode: FC<{ data: PostType }> = ({ data }) => {
  if (!data.description) return null;

  const exportPNG = () => {
    const canvas: any = document.querySelector('.HpQrcode canvas');

    canvas.toBlob(function (blob: any) {
      saveAs(blob, 'qr.png');
    });
  };
  // const exportSVG = () => {
  //   const canvas: any = document.querySelector('.HpQrcode canvas');

  //   canvas.toBlob(function (blob: any) {
  //     saveAs(blob, 'qr.svg');
  //   });
  // };

  return (
    <div className="HpQrcode">
      <Option
        avatar={
          <QRCode
            value={data.description}
            size={128}
            level={'H'}
            renderAs={'canvas'}
            bgColor={data.bgColor}
            fgColor={data.fgColor}
          />
        }
        title=""
        noBorder={true}
        buttons={[
          {
            title: 'PNG',
            onClick: () => exportPNG(),
            active: true,
          },
          //   {
          //     title: 'SVG',
          //     onClick: () => exportSVG(),
          //     active: true,
          //   },
        ]}
      />
    </div>
  );
};
