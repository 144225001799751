export const ADD = 'Add QR Code';
export const ADD_SERVICE = 'Add QR Code';
export const EXPORT_QR_CODE = 'Export';
export const SAVE = 'Save';
export const DELETE = 'Delete';
export const STATUS = 'Status';
export const TEXT_MY_POSTS = 'My QR Codes';
export const TITLE = 'Title';
export const DESCRIPTION = 'QR Code Content';
export const TYPE = 'QR Code Type';
export const URL = 'URL';
export const VIEW_PAGE = 'Preview';
export const EDIT = 'Edit';
export const EDIT_CONTENT = 'Content';
export const QRCODE_TYPE = 'QR code type';
export const BGCOLOR = 'Background color';
export const FGCOLOR = 'Foreground color';
export const MARGIN = 'Margin';
